<template>
  <CommonsModalsModalBody height="500px" :svg="svg">
    <template v-if="help" #help>
      {{ help }}
    </template>

    <template #title> {{ isEditMode ? "Modifier la" : "Ajouter une " }} personne </template>

    <template #content>
      <div v-show="changeMaximalAmountForExpense" class="np-form">
        <div class="np-row">
          <uds-input-amount
            label="Montant maximum par exercice pour les notes de frais"
            :value="devideBy100(partnerForm.maximalAmountForExpense ?? 0)"
            :error="err.maximalAmmountForExpenseNote"
            @change="partnerForm.maximalAmountForExpense = multiplyBy100($event) || 0"
          />
        </div>
      </div>
      <div v-show="!changeMaximalAmountForExpense" class="np-form">
        <div class="np-row">
          <uds-input-select-popper
            label="Type de personne"
            :value="partnerForm.idPartnerType"
            :items="partnerTypes"
            item-value="id"
            item-text="label"
            is-required
            @select="partnerForm.idPartnerType = $event || null"
          />
        </div>
        <div v-if="!isSocietyType" class="np-row">
          <uds-input-string
            label="Prénom"
            :value="partnerForm.firstname"
            :error="err.firstname"
            @change="partnerForm.firstname = $event || null"
          />
        </div>
        <div v-if="!isSocietyType" class="np-row">
          <uds-input-string
            label="Nom"
            :value="partnerForm.lastname"
            :error="err.lastname"
            @change="partnerForm.lastname = $event || null"
          />
        </div>
        <div v-if="isSocietyType" class="np-row">
          <CommonsInputsLegalName
            :selected-item="partnerForm.company"
            :error="err.company"
            @update="partnerForm.company = $event"
            @select="updatePartnerCompany($event)"
            @clear="clearValues"
          />
        </div>
        <div v-if="isSocietyType" class="np-row">
          <uds-input-string
            label="SIRET"
            :value="partnerForm.siret"
            :error="err.siret"
            @change="partnerForm.siret = $event || null"
          />
        </div>
        <div v-show="!isSocietyType" class="np-row">
          <uds-input-string
            label="Fonction"
            :value="partnerForm.fonction"
            :error="err.fonction"
            @change="partnerForm.fonction = $event || null"
          />
        </div>
        <div class="np-row">
          <uds-input-amount
            label="Solde initial"
            :value="devideBy100(partnerForm.soldeInit)"
            :error="err.soldeInit"
            @change="partnerForm.soldeInit = multiplyBy100($event) || 0"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button size="small" :disabled="!isValid" :loading="isLoading" @click="addPartner"
        >Enregistrer</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { ISocietyPartner, CreatePartner } from "@silexpert/core";
import { PartnerType } from "@silexpert/core";
import { multiplyBy100, devideBy100 } from "~/utils/amounts";

const partnerStore = usePartnerStore();
const categoryStore = useCategoryStore();
const societyStore = useSocietyStore();

const emit = defineEmits<{
  (e: "close" | "confirm", idPartner: number | undefined): void;
  (e: "cancel"): void;
}>();

const props = defineProps<{
  partner?: ISocietyPartner;
  help: string;
  svg?: string;
  changeMaximalAmountForExpense?: boolean;
}>();

onMounted(() => {
  if (props.partner) {
    isEditMode.value = true;
    partnerForm.value = Object.assign({}, partnerForm.value, {
      ...props.partner,
      soldeInit: props.partner.soldeInit.toString(),
      maximalAmountForExpense: (props.partner.maximalAmountForExpense ?? 0).toString(),
    });
  }
});

const isLoading = ref<boolean>(false);
const isEditMode = ref<boolean>(false);
const partnerForm = ref<CreatePartner>({
  lastname: "",
  firstname: "",
  fonction: "",
  company: "",
  siret: "",
  idPartnerType: PartnerType.EMPLOYEE,
  soldeInit: 0,
  maximalAmountForExpense: 0,
});

const partnerTypes = [
  { id: PartnerType.EMPLOYEE, label: "Employé" },
  { id: PartnerType.PHYSICAL_PARTNER, label: "Associé personne physique" },
  { id: PartnerType.MORAL_PARTNER, label: "Associé personne morale" },
];

const isSocietyType = computed<boolean>(
  () => partnerForm.value.idPartnerType === PartnerType.MORAL_PARTNER,
);

// Form Validation
const err = computed<{ [key: string]: string | null }>(() => {
  const { lastname, firstname, fonction, company, siret, maximalAmountForExpense } =
    partnerForm.value;

  return {
    ...(props.changeMaximalAmountForExpense
      ? {
          maximalAmountForExpense: !isDefined(maximalAmountForExpense)
            ? "Le champs est requis"
            : null,
        }
      : {
          company: isSocietyType.value && !isDefined(company) ? "Le champ est requis." : null,
          siret: isSocietyType.value && !isDefined(siret) ? "Le champ est requis." : null,
          lastname: !isSocietyType.value && !isDefined(lastname) ? "Le champ est requis." : null,
          firstname: !isSocietyType.value && !isDefined(firstname) ? "Le champ est requis." : null,
          fonction: !isSocietyType.value && !isDefined(fonction) ? "Le champ est requis." : null,
        }),
  };
});

const isValid = computed<boolean>(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

// Functions
function updatePartnerCompany(value: any) {
  partnerForm.value.company = value.company;
  partnerForm.value.siret = value.siretNumber;
}

function clearValues(): void {
  partnerForm.value.company = "";
  partnerForm.value.siret = "";
}

async function addPartner(): Promise<void> {
  isLoading.value = true;

  const idPartner = props.partner?.id ?? 0;

  let response = null;

  if (isEditMode.value) {
    await $silex()
      .partner.update(idPartner, partnerForm.value)
      .then((res) => {
        response = res;
        partnerStore.setPartner(props.partner!.id!, res);
        emit("close", response.id);
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });
  } else {
    await $silex()
      .partner.create(partnerForm.value)
      .then((res) => {
        response = res;
        partnerStore.partners.push(res);
        emit("close", response.id);
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });
  }

  await categoryStore.fetchSocietyCategories(societyStore.society!.id!);

  isLoading.value = false;

  $notifier().open({
    type: "success",
    content: `Collaborateur ${isEditMode.value ? "mis à jour" : "enregistré"}`,
  });
}
</script>

<style lang="scss" scoped>
.np-form {
  flex-grow: 1;

  .np-row {
    display: flex;
    &:not(:first-child) {
      margin-top: 15px;
    }

    > * {
      flex-basis: 100%;
      flex-grow: 1;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}
</style>
